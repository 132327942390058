import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import CountUp from 'react-countup'

import SubmitButton from '@components/submit-button/submit-button.component'
import CommonModal from '@components/common-modal/common-modal.component'
import { trackGa } from '@components/GoogleTracking'
import airplane from '@assets/images/icons/ionicons-paper-airplane.png'
import config from "@utils/config"

import './results.component.scss'


function calculateValues(props) {
  props.calculateValues()
}
// 
function countUpOrDownESS(props) {
  if (props.resultsHidden) {
    return <CountUp start={0} end={props.EssScore} delay={1} duration={5} useEasing={true} />
  } else {
    return <CountUp start={props.EssScore} end={0} delay={0} duration={4} useEasing={true} />
  }
}

class ResultsComponent extends React.Component {
  constructor(props) {
    super(props)

    this.emailResults = this.emailResults.bind(this)
    this.printResults = this.printResults.bind(this)
    this.handleCloseError = this.handleCloseError.bind(this)
    this.toggleEmailSection = this.toggleEmailSection.bind(this)

    this.state = {
      emailWithZipSubmitting: false,
      printWithZipSubmitting: false,
      emailSubmitting: false,
      showErrorModal: false,
      errors: null,
      showMessageSent: false,
      showEmailArea: false,
      location: null
    }
  }

  handleCloseError() {
    this.setState({
      showErrorModal: false
    })
  }

  toggleEmailSection(event) {
    event.preventDefault()
    trackGa('narcolepsy symptom screener ess chad, click, overemail')
    trackGa(`narcolepsy symptom screener ess chad, zipcode, ${(document.getElementById('zip-input').value)}`)

    this.setState(state => {
      return {
        showEmailArea: !state.showEmailArea
      }
    })
  }

  getErrorsFormatted(errors) {
    if (errors) {
      if (typeof errors === 'string') {
        return <p className="errors">{errors}</p>
      } else {
        return (
          <ul className="errors">
            {Object.keys(errors).map((value, index) => {
              return <li key={index}>{errors[value]}</li>
            })}
          </ul>
        )
      }
    }
  }

  getSymptomResultsRequest() {
    return {
      essScores: this.props.EssScores,
      essScore: this.props.EssScore,
      zip: document.getElementById('zip-input').value,
      email: document.getElementById('email-input').value,
      location: this.state.location
    }
  }

  printResults(event) {
    event.preventDefault()

    let screenerPdfWindow = window.open(
      `${config.REACT_APP_API_HOST}/symptom-screener-pediatric.php?data=${encodeURIComponent(
        JSON.stringify(this.getSymptomResultsRequest())
      )}`
    )
    screenerPdfWindow.focus()
    trackGa('narcolepsy symptom screener ess chad, click, overprint')
    trackGa(`narcolepsy symptom screener ess chad, zipcode, ${(document.getElementById('zip-input').value)}`)
  }

  emailResults(event) {
    event.preventDefault()
    trackGa('narcolepsy symptom screener ess chad, click, send email')

    let messageSent = () => {
      if (this.state.showMessageSent === true) {
        setTimeout(() => {
          this.setState({ showMessageSent: false })
        }, 4000)
      }
      trackGa('narcolepsy symptom screener ess chad, click, sent email')
      trackGa(`narcolepsy symptom screener ess chad, zipcode, ${(document.getElementById('zip-input').value)}`)
    }

    this.setState({ emailSubmitting: true }, () => {
      fetch(`${config.REACT_APP_API_HOST}/symptom-screener-pediatric.php`, {
        method: 'POST',
        body: JSON.stringify(this.getSymptomResultsRequest())
      })
        .then(res => {
          if (res.ok) {
            document.getElementById('zip-input').value = ''
            document.getElementById('email-input').value = ''
            return res.json()
          } else {
            this.setState({
              emailSubmitting: false,
              showErrorModal: true
            })
          }
        })
        .then(data => {
          if (data?.result) {
            this.setState(
              {
                emailSubmitting: false,
                showMessageSent: true
              },
              () => {
                messageSent()
              }
            )
          } else {
            this.setState({
              emailSubmitting: false,
              showErrorModal: true,
              errors: data?.errors
            })
          }
        })
    })
  }

  messageSending(itSent) {
    return itSent ? 'message-sent-overlay-results' : 'display-none'
  }

  emailResultsArea() {
    return this.state.showEmailArea ? 'send-results' : 'display-none'
  }

  setupAutocomplete() {
    let input = this.zipInput
    let options = {
      types: ['(regions)'],
      componentRestrictions: { country: 'us' }
    }
    let autocomplete = new window.google.maps.places.Autocomplete(input, options)

    new window.google.maps.event.addListener(autocomplete, 'place_changed', () => {
      var place = autocomplete.getPlace()

      if (!place.geometry) {
        this.setState({
          location: null
        })
      }

      this.setState({
        location: {
          lat: autocomplete.getPlace().geometry.location.lat(),
          lng: autocomplete.getPlace().geometry.location.lng()
        }
      })
    })
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.calculated && this.props.calculated) {
      this.setupAutocomplete()
    }
  }

  render() {
    return (
      <div className="results-wrapper-ped">
        <h2 className="section-header results">Results</h2>
        {!this.props.calculated ? (
          <div className="results-overlay-precalc">
            <div className="calculate-overlay" />
            <div className="calculate-overlay with-button">
              <button className="btn-primary btn-calculate" onClick={() => calculateValues(this.props)}>
                Calculate <FontAwesomeIcon icon="angle-right" />
              </button>
            </div>
            <div className="results-card results-card-wrapper pre-results primary-card">
              <div className="score-details">
                <div className="score-value">
                  <h4 className="explination-header">Your patient’s ESS-CHAD score is:</h4>
                  <p>
                    An ESS-CHAD score &gt;10 suggests excessive daytime sleepiness.<sup>2,3,5</sup> The ESS-CHAD score
                    should be discussed with a sleep specialist.
                  </p>
                </div>
                <section className="score-chart-section">
                  <h4>
                    Interpreting ESS-CHAD Scores<sup>3-5*</sup>
                  </h4>
                  <div className="chart">
                    <div className="scale-details pre-details">
                      <div className="normal-levels pre">
                        <p>Normal levels of sleepiness</p>
                      </div>
                      <div className="excessive-levels pre">
                        <p>Excessive daytime sleepiness</p>
                      </div>
                      <div className="high-levels pre">
                        <p>High level of sleepiness suggestive of significant sleep disorder</p>
                      </div>
                    </div>
                    <div className="scale-values">
                      <h6 className="first-value">0</h6>
                      <h6 className="second-value">10</h6>
                      <h6 className="third-value">16</h6>
                      <h6 className="fourth-value">24</h6>
                    </div>
                    <div className="scale-line">
                      <div className="normal-line" />
                      <div className="excessive-line" />
                      <div className="high-line" />
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        ) : null}
        {this.props.calculated ? (
          <div className="results-card-wrapper post-calc">
            <div className="results-card post-calc">
              <div className="score-details">
                <div className="score-value">
                  <h4 className="explination-header">Your patient’s ESS-CHAD score is:</h4>
                  <h5>{countUpOrDownESS(this.props)}</h5>
                  <p>
                    An ESS-CHAD score &gt;10 suggests excessive daytime sleepiness.<sup>2,3,5</sup> The ESS-CHAD score
                    should be discussed with a sleep specialist.
                  </p>
                </div>
                <section className="score-chart-section">
                  <h4>
                    Interpreting ESS-CHAD Scores<sup>3-5*</sup>
                  </h4>
                  <div className="chart">
                    <div className="scale-details">
                      <div className="normal-levels">
                        <p>Normal levels of sleepiness</p>
                      </div>
                      <div className="excessive-levels">
                        <p>Excessive daytime sleepiness</p>
                      </div>
                      <div className="high-levels">
                        <p>High level of sleepiness suggestive of significant sleep disorder</p>
                      </div>
                    </div>
                    <div className="scale-values">
                      <h6 className="first-value">0</h6>
                      <h6 className="second-value">10</h6>
                      <h6 className="third-value">16</h6>
                      <h6 className="fourth-value">24</h6>
                    </div>
                    <div className="scale-line">
                      <div className="normal-line" />
                      <div className="excessive-line" />
                      <div className="high-line" />
                    </div>
                    <div className="ess-marker-holder">
                      <div className="ess-marker-verticle">
                        <FontAwesomeIcon icon="map-marker-alt" />
                      </div>
                      <div className="ess-marker-horizontal-md">
                        <FontAwesomeIcon icon="map-marker-alt" />
                      </div>
                      <div className="ess-marker-horizontal-lg">
                        <FontAwesomeIcon icon="map-marker-alt" />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="receive-results">
                <input
                  id="collapsible-email"
                  className="toggle"
                  type="checkbox"
                  onClick={() => {
                    trackGa('narcolepsy symptom screener ess chad, click, print')
                  }}
                />
                <label htmlFor="collapsible-email" className="arrow-toggle">
                  Print, Download, or Email Results
                  <div className="arrow-rotate">
                    <FontAwesomeIcon icon="angle-right" />
                  </div>
                </label>
                <div className="collapsible-content">
                  <div className="content-inner">
                    <form onSubmit={this.emailResults}>
                      <div className={this.messageSending(this.state.showMessageSent)}>
                        <img src={airplane} />
                        Message Sent
                      </div>
                      <div className="nearby-results">
                        <p>Would you like a list of nearby sleep specialists?</p>
                        <p>
                          Enter ZIP code: <span className="send-details">(Optional)</span>{' '}
                        </p>
                        <p className="send-details">
                          Results document will include up to 5 sleep specialists closest to your patient.
                        </p>
                        <input
                          placeholder="ZIP Code"
                          className="zip-input"
                          id="zip-input"
                          ref={input => (this.zipInput = input)}
                        />
                        <button
                          className="results-button"
                          id="message-sent"
                          onClick={(() => {}, this.toggleEmailSection)}
                        >
                          Email Document
                        </button>
                        <SubmitButton
                          onClick={(() => {}, this.printResults)}
                          className="results-button"
                          text="Print Document"
                        />
                      </div>
                      <div className={this.emailResultsArea()}>
                        <div className="send-results-input-title">
                          <label htmlFor="email-input" className="email-label">
                            Enter an email address:
                          </label>
                          <input
                            id="email-input"
                            className="email-input"
                            type="email"
                            placeholder="Email Address"
                            pattern="[a-zA-Z0-9.~+_\-]{1,}@[a-zA-Z.\-]{2,}[.]{1}[a-zA-Z]{2,}"
                            required
                          />
                        </div>
                        <SubmitButton className="email-button" loading={this.state.emailSubmitting} text="Send Email" />
                      </div>
                    </form>
                  </div>
                </div>
                <CommonModal isShowModal={this.state.showErrorModal}>
                  <div className="body-content">
                    <h2 className="title">Error</h2>
                    <p className="content">A server error occurred while submitting your request.</p>
                    {this.getErrorsFormatted(this.state.errors)}
                    <div className="action">
                      <button className="button btn-primary" onClick={this.handleCloseError}>
                        Ok
                      </button>
                    </div>
                  </div>
                </CommonModal>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    )
  }
}

ResultsComponent.propTypes = {
  calculateValues: PropTypes.func,
  EssScores: PropTypes.object,
  EssScore: PropTypes.number,
  calculated: PropTypes.bool,
  resultsHidden: PropTypes.bool
}

export default ResultsComponent
