import React from 'react'
import PropTypes from 'prop-types'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { win } from '@src/app-utils'

import './checkbox.component.scss'

function handleOptionChange(changeEvent) {
  changeEvent.optionSelect(changeEvent)
  let checkBox = document.getElementById(changeEvent.radioGroup + changeEvent.number)
  let windowWidth = win && window.innerWidth
  if (windowWidth <= 750) {
    checkBox.checked = true
  }
}

const CheckboxComponent = props => {
  return (
    <div className="single-response" onClick={() => handleOptionChange(props)}>
      <div className="nl-checkbox">
        <input
          type="radio"
          id={props.radioGroup + props.number}
          name={props.radioGroup}
          onChange={() => handleOptionChange(props)}
        />
        <label htmlFor={props.radioGroup + props.number}>{props.number}</label>
        <div className="check-mark">
          <FontAwesomeIcon icon="check" />
        </div>
      </div>
      <label className="mobile-checkbox-label">{props.label}</label>
    </div>
  )
}

export default CheckboxComponent

CheckboxComponent.propTypes = {
  optionSelect: PropTypes.func,
  number: PropTypes.any,
  radioGroup: PropTypes.string,
  question: PropTypes.string,
  label: PropTypes.any,
  quizGroup: PropTypes.string
}
