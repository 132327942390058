import React, { Component } from 'react'
import Layout from '@components/layout/Layout'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { CSSTransition } from 'react-transition-group'

import EssScreenerComponent from '@components/screeners-pediatric/ess-screener/ess-screener.component'
import ResultsComponent from '@components/screeners-pediatric/results/results.component'
import SiteLink from '@components/SiteLink'
import OptionalInformation from '@components/screeners-pediatric/optional-info/optional-info.component'
import ShareResultsExplanation from '@components/screeners-pediatric/share-results-explanation/share-results-explanation.component'
import ReferenceComponent from '@components/references/reference.component'
import ReadNext from '@components/read-next-button/read-next-button'

import { trackGa } from '@components/GoogleTracking'

import './symptom-screener-pediatric.scss'

class PediatricScreening extends Component {
  constructor(props) {
    super(props)

    this.state = {
      EssScore: 0,
      calculated: false,
      resultsHidden: true,
      iOS: false,
      calculating: false,
      gender: '',
      age: ''
    }

    this.calcValues = this.calcValues.bind(this)
    this.setResponse = this.setResponse.bind(this)
    this.setDevice = this.setDevice.bind(this)
    this.qualification = this.qualification.bind(this)
    this.optionalInfoGenderTracking = this.optionalInfoGenderTracking.bind(this)
    this.optionalInfoAgeTracking = this.optionalInfoAgeTracking.bind(this)

    this.EssScores = {}
  }

  componentDidMount() {
    this.setDevice()
  }

  setDevice() {
    var device = /iPad|iPhone/.test(navigator.userAgent) && !window.MSStream
    if (device) {
      return true
    }
  }

  setResponse(responseValue) {
    this[responseValue.quizGroup] = {
      ...this[responseValue.quizGroup],
      [responseValue.radioGroup]: responseValue.number
    }
  }

  calcEss() {
    let total = 0

    for (let key in this.EssScores) {
      if (!isNaN(this.EssScores[key]) && this.EssScores[key] !== false) total += parseInt(this.EssScores[key])
    }

    trackGa('narcolepsy symptom screener ess chad, ess_chad_score, calculate - ess chad score')
    trackGa(`narcolepsy symptom screener ess chad, ess_chad_score, ${this.qualification(total)}`)
    trackGa(`narcolepsy symptom screener ess chad, ess_chad_score, ${total}`)
    localStorage.setItem('EssChadScores', JSON.stringify(this.EssScores))
    localStorage.setItem('EssChadScore', total)
    return total
  }

  qualification(total) {
    if (total >= 10) return 'qualified'
    else return 'not qualified'
  }

  optionalInfoGenderTracking(gender) {
    if (gender) {
      if (gender === 'male') {
        this.setState({ gender: 'male' })
      } else {
        this.setState({ gender: 'female' })
      }
    }
  }

  optionalInfoAgeTracking(age) {
    if (age) {
      if (age === '1') {
        this.setState({ age: 'age less than 12' })
      } else {
        this.setState({ age: 'age 12-18' })
      }
    }
  }

  calcValues() {
    const scores = this.EssScores
    Object.entries(scores).map(function(key) {
      const chadKey = key[0].replace('ess', 'narcolepsy symptom screener ess chad, esschad_q')
      const chadValue = key[1].toString()
      trackGa(`${chadKey}, ${chadValue}`)
    })
    trackGa(`narcolepsy symptom screener ess chad,	optional - ess chad, ${this.state.gender} `)
    trackGa(`narcolepsy symptom screener ess chad,	optional - ess chad, ${this.state.age} `)
    trackGa('narcolepsy symptom screener - ess chad, click, calculate')
    this.calculatingAnimStart()
    setTimeout(() => {
      this.setState(
        {
          EssScore: this.calcEss(),
          calculated: true,
          resultsHidden: false,
          iOS: this.setDevice()
        },
        () => {
          this.calcLeftEss()
          this.scrollToResults()
        }
      )
      this.calculatingAnimFinish()
    }, 1500)
  }

  scrollToResults() {
    let results = document.getElementById('scroll-to-results')

    if (results) {
      window.scrollTo(0, results.offsetTop - 20)
    }
  }

  calculatingAnimStart() {
    let btnCalculate = document.getElementsByClassName('btn-calculate')
    let btnCalculating = document.getElementsByClassName('btn-calculating')
    let opacityContainer = document.getElementsByClassName('opacity-container')
    if (btnCalculate[0]) {
      btnCalculate[0].style.display = 'none'
    }
    if (btnCalculating[0]) {
      let windowSize = window.innerWidth
      btnCalculating[0].style.display = 'flex'
      btnCalculating[0].style.zIndex = '200'
      if (windowSize >= 320 && windowSize < 341) {
        btnCalculating[0].style.bottom = '610px'
      } else if (windowSize >= 341 && windowSize < 375) {
        btnCalculating[0].style.bottom = '585px'
      } else if (windowSize >= 375 && windowSize < 418) {
        btnCalculating[0].style.bottom = '568px'
      } else if (windowSize >= 418 && windowSize < 446) {
        btnCalculating[0].style.bottom = '550px'
      } else if (windowSize >= 446 && windowSize < 460) {
        btnCalculating[0].style.bottom = '533px'
      } else if (windowSize >= 460 && windowSize < 527) {
        btnCalculating[0].style.bottom = '508px'
      } else if (windowSize >= 527 && windowSize < 576) {
        btnCalculating[0].style.bottom = '490px'
      } else if (windowSize >= 576 && windowSize < 616) {
        btnCalculating[0].style.bottom = '503px'
      } else if (windowSize >= 616 && windowSize < 652) {
        btnCalculating[0].style.bottom = '478px'
      } else if (windowSize >= 652 && windowSize < 750) {
        btnCalculating[0].style.bottom = '460px'
      } else if (windowSize >= 750 && windowSize < 994) {
        btnCalculating[0].style.bottom = '360px'
      } else if (windowSize >= 994 && windowSize < 1170) {
        btnCalculating[0].style.bottom = '325px'
        btnCalculating[0].style.marginLeft = '-137px'
      } else if (windowSize >= 1170) {
        btnCalculating[0].style.bottom = '305px'
      }
    }
    if (opacityContainer[0]) {
      opacityContainer[0].style.opacity = '0.5'
    }
  }

  calculatingAnimFinish() {
    let opacityContainer = document.getElementsByClassName('opacity-container')
    let btnCalculating = document.getElementsByClassName('btn-calculating')
    if (opacityContainer[0]) {
      opacityContainer[0].style.opacity = '1'
    }
    if (btnCalculating[0]) {
      btnCalculating[0].style.display = 'none'
    }
  }

  calcLeftEss() {
    let multiplier = 0
    let left = 0
    let score = this.state.EssScore
    let windowWidth = window.innerWidth
    let max = 0

    if (windowWidth < 750) {
      multiplier = 9.35
      left = score * multiplier
      max = 220
      this.moveEss(left, 'ess-marker-verticle', max)
    } else if (windowWidth >= 750 && windowWidth < 994) {
      multiplier = 16.5
      left = score * multiplier
      max = 390
      this.moveEss(left, 'ess-marker-horizontal-md', max)
    } else if (windowWidth >= 994) {
      multiplier = 22
      left = score * multiplier
      max = 525
      this.moveEss(left, 'ess-marker-horizontal-lg', max)
    }
  }

  moveEss(left, className, max) {
    var elem = document.getElementsByClassName(className)
    var pos = 0
    var id = setInterval(frame, 5)
    function frame() {
      if (elem[0]) {
        if (max === 220) {
          if (left <= max) {
            if (pos >= left) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          } else {
            if (pos >= max) {
              clearInterval(id)
            } else {
              pos++
              elem[0].style.top = pos + 'px'
            }
          }
        } else {
          if (pos >= left) {
            clearInterval(id)
          } else {
            pos++
            elem[0].style.left = pos + 'px'
          }
        }
      }
    }
  }

  render() {
    const readNext = {
      readNextContent: 'Patient/Caregiver Perspectives',
      goTo: '/pediatric-patients/patient-and-caregiver-perspectives/',
      tracking: 'pediatric screening,	click, patient/caregiver perspectives - pediatric screening',
    }
    const references = [
      {
        content:
          'Maski K, Owens JA. Insomnia, parasomnias, and narcolepsy in children: clinical features, diagnosis, and management. ',
        source: ' Lancet Neurol. ',
        info: '2016;15(11):1170-1181.'
      },
      {
        content:
          'Wang YG, Benmedjahed K, Lambert J, et al. Assessing narcolepsy with cataplexy in children and adolescents: development of a cataplexy diary and the ESS-CHAD.',
        source: 'Nat Sci Sleep. ',
        info: '2017;9:201-211.'
      },
      {
        content:
          "Janssen KC, Phillipson S, O'Connor J, Johns MW. Validation of the Epworth Sleepiness Scale for Children and Adolescents using Rasch analysis.",
        source: 'Sleep Med. ',
        info: '2017;33:30-35. '
      },
      {
        content: 'Johns MW. A new method for measuring daytime sleepiness: the Epworth Sleepiness Scale. ',
        source: 'Sleep. ',
        info: '1991;14(6):540-545.'
      },
      {
        content:
          ' Johns M, Hocking B. Excessive daytime sleepiness: daytime sleepiness and sleep habits of Australian workers. ',
        source: ' Sleep. ',
        info: '1997;20(10):844-849.'
      },
      {
        content: 'American Academy of Sleep Medicine. Central disorders of hypersomnolence. In: ',
        source: ' The International Classification of Sleep Disorders – Third Edition (ICSD-3) Online Version. ',
        info: 'Darien, IL: American Academy of Sleep Medicine; 2014.'
      }
    ]

    return (
      <Layout addedClass="page-symptom-screener-ped" location={this.props.location}>
        <div className="opacity-container">
          <div className="primary-container">
            <h1 className="main-page-header">Narcolepsy Screening for Pediatric Patients</h1>
            <div className="paragraph-learn-more">
              <div className="header-paragraph-wrapper">
                <p className="body-copy">
                  Screening for sleep problems in pediatric patients is important, particularly in children with
                  neurodevelopmental disorders.<sup>1</sup> The Epworth Sleepiness Scale for Children and Adolescents
                  (ESS-CHAD) is a validated screening tool for use in pediatric patients 12 to 18 years of age.<sup>
                    2,3
                  </sup>
                </p>
              </div>
            </div>
          </div>
          <div className="section-gradient" />
          <div className="primary-container quiz-section">
            <h2 className="section-header">Measure Your Patient&#8217;s Sleepiness</h2>
            <div className="header-link-wrapper">
              <h2 className="tertiary-header">
                ESS-CHAD<sup>2</sup>
              </h2>{' '}
              <SiteLink to="/pediatric/recognize/epworth-sleepiness-scale-for-children-and-adolescents/" tracking="pediatric screening, click, ess chad subpage- pediatric screening">
                Learn more about the ESS-CHAD
              </SiteLink>
              <span className="desktop-only" style={{margin: '0px 10px 0px 20px', fontSize: '24p', color: '#ccc', position: 'relative', top: '0.15em'}}>|</span> 
              <SiteLink to="/resources/resource-center/" tracking="pediatric screening, click, clinical resources - pediatric screening">
                Download screening tools
              </SiteLink>
            </div>
            <p className="quiz-description ess-description">
              Ask your patient how likely he or she has been able to fall asleep over the past month while doing the
              things that are described below (activities). Ensure each question is answered by the patient or his or
              her caregiver. If the patient has not done any of the activities over the past month, ask the patient to
              imagine how the situation would affect him or her.
            </p>
            <EssScreenerComponent selectResponse={this.setResponse} />
            <p className="sources-text">
              Reprinted with permission from Wang YG, Benmedjahed K, Lambert J, et al. Assessing narcolepsy with
              cataplexy in children and adolescents: development of a cataplexy diary and the ESS-CHAD.{' '}
              <i>Nat Sci Sleep.</i> 2017;9:201-211. Permission conveyed through Copyright Clearance Center, Inc.
            </p>
          </div>
          <div className="optional-results-wrapper">
            <OptionalInformation
              genderTracking={this.optionalInfoGenderTracking}
              ageTracking={this.optionalInfoAgeTracking}
            />
          </div>
          {this.state.calculated ? (
            <div className="primary-container">
              <div className="link-button">
                <button
                  onClick={() => {
                    this.calcValues()
                  }}
                >
                  CALCULATE<span className="button-arrow">
                    <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                  </span>
                </button>
              </div>
            </div>
          ) : null}
          <CSSTransition in={!this.state.resultsHidden} timeout={{ enter: 3000, exit: 3000 }} classNames="animation">
            <div id="scroll-to-results">
              <div className="optional-results-wrapper">
                <ResultsComponent
                  calculateValues={this.calcValues}
                  EssScore={this.state.EssScore}
                  EssScores={this.EssScores}
                  calculated={this.state.calculated}
                  resultsHidden={!this.state.resultsHidden}
                />
              </div>
              <div className="primary-container">
                {this.state.calculated ? (
                  <div>
                    <div className="screen-link-wrapper">
                      <div>
                        <SiteLink
                          to="/screening-and-diagnosis/screeners/"
                          className="link-text screen-adult-patient-link"
                          tracking="narcolepsy symptom screener ess chad, click, screen an adult patient CTA bottom - screener"
                        >
                          Screen an adult patient<span className="button-arrow">
                            <FontAwesomeIcon className="arrow-right" icon="angle-right" />
                          </span>
                        </SiteLink>
                      </div>
                      <p>
                        These screening tools are not intended to make a narcolepsy diagnosis or replace complete
                        evaluation by a sleep specialist.
                      </p>
                    </div>
                  </div>
                ) : null}
              </div>
              {this.state.calculated ? (
                <div className="optional-results-wrapper">
                  <ShareResultsExplanation />
                </div>
              ) : null}
            </div>
          </CSSTransition>
          <div className="primary-container">
            <div className="disclaimer">
              <p>
                *This screening tool is not intended to make a narcolepsy diagnosis or replace complete evaluation by a
                sleep specialist.
              </p>
              <br />
              <p>
                The content on this site is not meant to replace a conversation with a sleep specialist. A sleep
                specialist can evaluate for symptoms and make a diagnosis. A narcolepsy diagnosis should be established
                with a clinical interview and nighttime polysomnography (PSG) followed by a multiple sleep latency test
                (MSLT).<sup>6</sup>
              </p>
            </div>
            <ReadNext readNext={readNext} />
          </div>
          <ReferenceComponent referenceList={references} />
        </div>
        <div className="btn-primary btn-calculating">
          Calculating <FontAwesomeIcon className="loading" icon={faSpinner} spin />
        </div>
      </Layout>
    )
  }
  isIos(isIdevice) {
    return isIdevice ? 'margin-zero' : ''
  }
  isCalculating(calculating) {
    return calculating ? 'background-overlay' : 'no-overlay'
  }
}

export default PediatricScreening
