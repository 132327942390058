import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import SiteLink from '@components/SiteLink'

import './share-results-explanation.component.scss'


const ShareResultsExplanation = () => {
  return (
    <div className="share-results-ped">
      <div className="share-explanation">
        <h3 className="section-header">Share These Results With a Sleep Specialist</h3>
        <p>
          The ESS-CHAD can provide important information for a sleep specialist in screening for excessive daytime
          sleepiness in narcolepsy. Have your patients and their caregivers share these important scores with a sleep
          specialist and review the diagnostic sleep tests that help identify narcolepsy.
        </p>
      </div>
      <div className="share-results-link-wrapper">
        <div className="share-results-link">
          <SiteLink
            to="/stay-connected/locate-a-sleep-specialist/"
            tracking="narcolepsy symptom screener ess chad, click, locate sleep specialist button - screener"
          >
            <div className="page-name">locate a nearby sleep specialist</div>
            <FontAwesomeIcon className="arrow-right" icon="angle-right" />
          </SiteLink>
        </div>
      </div>
    </div>
  )
}

export default ShareResultsExplanation
